import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_STACKINGPATTERN } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Stacking Pattern", "width": 832 } }, [_c("text-input", { key: "description", attrs: { "label": "Description", "rules": "required", "span": 24, "disabled": !_vm.hasMaintainPermission } }), _c("stacking-pattern-upload", { key: "picFrontDomain,picBackDomain,picFrontPath,picBackPath", attrs: { "title": "If only one image, insert it into the Front Picture", "arr-upload": _vm.uploadFields, "style-input": _vm.styleInput, "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateStackingPattern",
  inject: ["can"],
  data() {
    return {
      styleInput: {
        width: "50%",
        height: "419px"
      },
      arrUpload: [
        { key: "picFrontPath", text: "Front Picture" },
        { key: "picBackPath", text: "Back Picture" }
      ],
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(this.permissions.maintain);
    },
    uploadFields() {
      return this.arrUpload.map((field) => Object.assign(field, { disabled: !this.hasMaintainPermission }));
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateStackingPattern = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-stacking-pattern" }, [_c("resource", { attrs: { "page": _vm.page, "name": "sterilization.stacking-patterns", "api-url": _vm.apiUrl, "create": _vm.CreateStackingPattern, "redirect-route": "/sterilization-specifications/stacking-pattern" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateStackingPattern",
  data() {
    return {
      CreateStackingPattern,
      page: STERILIZATION_SPECIFICATIONS_STACKINGPATTERN,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
